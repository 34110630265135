<template>
  <div class="text-center">
    <v-dialog v-model="dialogInterno" width="600" persistent>
      <v-card>
        <v-card-title class="lighten-2">
          Aviso
        </v-card-title>

        <v-card-text>
          O Simplificador busca garantir boas práticas de segurança e
          transparência para todos os clientes, por isso estamos solicitando a
          redefinição de sua senha para deixá-la mais segura.
          <br />
          <p class="my-2">
            Dicas para a criação de uma senha segura:
            <ul>
              <li>Evite sequências numéricas;</li>
              <li>Deve conter mais de oito caracteres;</li>
              <li>Deve conter números e letras maiúsculas e minúsculas;</li>
              <li>Deve conter pelo menos um caractere especial: !@#$%¨&* </li>
            </ul>
          </p>
          <div class="mt-10">
            <v-form ref="form" v-model="valid" >
              <inputPassword v-model="password" :password="{ label: 'Digite sua Nova Senha'}" :passwordConfirm="{ label: 'Confirme a Nova Senha'}" ></inputPassword>
              <div class="actions text-center">
                <v-btn
                  color="success"
                  :loading="loading"
                  @click="passwordChange()"
                >
                  Alterar
                </v-btn>
              </div>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import inputPassword from './inputPassword.vue'
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  components:{
    inputPassword
  },
  computed: {
    ...mapGetters({
      userData: 'changePassword/userData',
    }),
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
        this.dialogInterno = value
      },
    },
  },
  data() {
    return {
      dialogInterno: true,
      loading: false,
      password: '',
      valid: false,
    }
  },
  methods: {
    passwordChange() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$store
          .dispatch('changePassword/passwordChange', {
            password:this.password
          }).then(() => {
            this.loading = false;
            this.dialogInterno = false
          })
      }

    },
  },

}
</script>
